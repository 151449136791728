<template>
  <a v-on:click.prevent="handleClick" :href="'/' + group + '/' + index">
    <slot />
  </a>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["alt", "directoryLink"],
  computed: {
    ...mapState(["group", "section", "route"]),
    index() {
      return this.directoryLink || this.section;
    },
  },
  methods: {
    handleClick() {
      this.alt ? this.$emit("alt-event") : this.routeBack();
    },
    routeBack() {
      let routeOptions = {
        name: [this.group, this.index].join("."),
      };

      if (this.directoryLink) routeOptions.params = { internal: true };

      this.route.params.internal
        ? this.$router.back()
        : this.$router.push(routeOptions);
    },
  },
};
</script>

<template>
  <div class="thumbnails">
    <ul>
      <li v-for="(item, index) in paintings" :key="index">
        <div class="thumb-image">
          <div class="media-wrapper" @click="$emit('slide-to', index)">
            <div class="media">
              <img :src="`${item.dir}th-${item.duplicate}`" />
            </div>
          </div>
        </div>
        <p class="thumb-caption" v-html="item.title" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["paintings"],
};
</script>

<style>
.thumbnails {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: var(--background-color);
  overflow: auto;
  z-index: 2;
}

.thumbnails ul {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  list-style: none;
  margin-top: 4rem;
}

.thumbnails ul li {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 3.25rem;
  margin-bottom: 3.25rem;
  cursor: url("~@/assets/img/icons/cursor_pointer.png") 6 0, pointer !important;
}

.thumbnails .media-wrapper,
.thumbnails .media {
  width: 100%;
  display: flex;
}

.thumbnails .media img {
  display: inline-block;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  vertical-align: top;
}

.thumbnails .thumb-caption {
  margin-top: 1.5rem;
  font-family: Graphik, Helvetica Neue, Helvetica, sans-serif;
  font-weight: 200;
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.007rem;
  text-align: center;
}

@media only screen and (min-width: 460px) {
  .thumbnails ul li {
    margin-right: 7%;
  }

  .thumbnails ul li:before {
    content: "";
    position: absolute;
  }

  .thumbnails .thumb-image {
    position: relative;
    height: 0;
    padding-bottom: 100%;
  }

  .thumbnails .media-wrapper {
    position: absolute;
    height: 100%;
  }
}

@media only screen and (min-width: 460px) and (max-width: 699px) {
  .thumbnails ul li {
    width: 46.5%;
  }

  .thumbnails ul li:nth-child(2n) {
    margin-right: 0;
  }
}

@media only screen and (min-width: 700px) {
  .thumbnails ul li {
    width: 28.66667%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 959px) {
  .thumbnails ul li:nth-child(3n) {
    margin-right: 0;
  }
}

@media only screen and (min-width: 769px) {
  .thumbnails .thumb-caption {
    opacity: 0;
    transition: 0.2s ease-out;
  }

  .thumbnails ul li:hover .thumb-caption {
    opacity: 1;
  }
}

@media only screen and (min-width: 960px) {
  .thumbnails {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .thumbnails ul li {
    width: 19.75%;
  }

  .thumbnails ul li:nth-child(4n) {
    margin-right: 0;
  }
}

@media only screen and (min-width: 1025px) {
  .thumbnails {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  }
}
</style>

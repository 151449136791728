<template>
  <button
    class="fullscreen-button button"
    :title="isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'"
    v-on:click="toggleFullscreen"
  />
</template>

<script>
import store from "@/store";
import { OPEN_FULLSCREEN, CLOSE_FULLSCREEN } from "@/store/handlers";
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState(["fullscreenSupport"]),
    ...mapGetters(["isFullscreen"]),
  },
  methods: {
    toggleFullscreen() {
      if (this.isFullscreen) {
        store.dispatch(CLOSE_FULLSCREEN);
      } else {
        store.dispatch(OPEN_FULLSCREEN, document.querySelector(".exhibits"));
      }
    },
  },
};
</script>

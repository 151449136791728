<template>
  <div class="exhibits" :class="{ loading: isProjectLoading }">
    <div v-if="isProjectReady" class="image-gallery">
      <swiper
        :slidesPerView="'auto'"
        :speed="800"
        :preloadImages="false"
        :resizeObserver="true"
        :navigation="{
          nextEl: '.next',
          prevEl: '.prev',
          disabledClass: 'disabled',
        }"
        :keyboard="true"
        :lazy="{
          loadPrevNext: false,
          preloaderClass: 'lazy-preloader',
        }"
        @swiper="init"
        @slideChange="onSlideChange"
        @slideChangeTransitionEnd="onSlideChangeTransitionEnd"
      >
        <swiper-slide v-for="(item, index) in paintings" :key="index">
          <GallerySlide
            v-bind="item"
            :is-current="currentSlide == index"
            :install="project.multi ? installs[index] : null"
          />
        </swiper-slide>
        <button class="prev-next-button prev">
          <svg viewBox="0 0 100 100">
            <path
              d="M 16.85,50 L 63.26,96.41 L 67.19,92.47999999999999 L 24.71,50  L 67.19,7.520000000000003 L 63.26,3.5900000000000034 Z"
            />
          </svg>
        </button>
        <button class="prev-next-button next">
          <svg viewBox="0 0 100 100">
            <path
              d="M 16.85,50 L 63.26,96.41 L 67.19,92.47999999999999 L 24.71,50  L 67.19,7.520000000000003 L 63.26,3.5900000000000034 Z"
              transform="translate(100, 100) rotate(180)"
            />
          </svg>
        </button>
      </swiper>
      <GalleryGrid
        v-if="thumbnails"
        :paintings="paintings"
        v-on:slide-to="slideToArtwork"
      />
      <button
        class="thumbs-button button"
        :title="thumbnails ? 'Show gallery' : 'Show thumbnails'"
        @click="thumbnails = !thumbnails"
      >
        <svg viewBox="0 0 22 16">
          <path
            d="M0.5,0.5H21.5V15.5H0.5Z"
            stroke="#FFFFFF"
            stroke-width="1"
            fill="none"
          />
          <path
            v-if="!thumbnails"
            d="M0.5,5.5H21.5 M0.5,10.5H21.5 M7.5,0.5V15.5 M14.5,0.5V15.5"
            stroke="#FFFFFF"
            stroke-width="1"
            fill="none"
          />
        </svg>
      </button>
      <BackButton
        class="close-button button"
        title="Close gallery"
        :alt="!isDesktop && !thumbnails"
        v-on:alt-event="thumbnails = true"
      />
      <FullscreenButton v-if="fullscreenSupport && fullscreen !== 'global'" />
      <div class="slide-progress">
        {{ slideProgress }}
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { LOAD_PROJECT, CLOSE_FULLSCREEN } from "@/store/handlers";
import { mapState, mapGetters } from "vuex";
import GallerySlide from "@/components/GallerySlide";
import GalleryGrid from "@/components/GalleryGrid";
import BackButton from "@/components/BackButton";
import FullscreenButton from "@/components/FullscreenButton";
import SwiperCore, { Navigation, Keyboard, Lazy } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.min.css";
SwiperCore.use([Navigation, Keyboard, Lazy]);
export default {
  components: {
    Swiper,
    SwiperSlide,
    GallerySlide,
    GalleryGrid,
    BackButton,
    FullscreenButton,
  },
  data() {
    return {
      slideshow: null,
      activeSlide: 0,
      currentSlide: 0,
      thumbnails: true,
      unmaximised: true,
    };
  },
  computed: {
    ...mapState([
      "fullscreen",
      "fullscreenSupport",
      "media",
      "project",
      "status",
    ]),
    ...mapGetters([
      "isDesktop",
      "isFullscreen",
      "isProjectLoading",
      "isProjectShown",
      "isProjectReady",
    ]),
    paintings() {
      return this.project.multi
        ? this.media.filter((v, i) => !(i % 2))
        : this.media;
    },
    installs() {
      return this.project.multi
        ? this.media.filter((v, i) => i % 2)
        : undefined;
    },
    slideProgress() {
      return this.activeSlide + 1 + " of " + this.paintings.length;
    },
  },
  watch: {
    isFullscreen() {
      this.$nextTick(() => {
        this.slideshow.update();
      });
    },
  },
  methods: {
    init(swiper) {
      this.slideshow = swiper;
    },
    onSlideChange(swiper) {
      this.activeSlide = swiper.activeIndex;
    },
    onSlideChangeTransitionEnd(swiper) {
      this.currentSlide = swiper.activeIndex;
    },
    slideToArtwork(index) {
      this.slideshow.slideTo(index, 0);
      this.thumbnails = false;
    },
    toggleSlideshow(bool) {
      this.slideshow.allowTouchMove = bool;
    },
  },
  beforeMount() {
    store.dispatch(LOAD_PROJECT, this.project.refId);
    if (this.isDesktop) this.thumbnails = false;
  },
  beforeRouteLeave(to, from, next) {
    if (this.fullscreen === "local") {
      store.dispatch(CLOSE_FULLSCREEN).then(() => {
        let watcher = store.watch(
          function (state) {
            return state.fullscreen;
          },
          function () {
            watcher();
            next();
          }
        );
      });
    } else next();
  },
};
</script>

<style>
.image-gallery,
.image-gallery > .swiper-container {
  width: 100%;
  height: 100%;
}

.image-gallery > .swiper-container {
  padding-bottom: 10rem;
}

@media only screen and (min-width: 769px) {
  .image-gallery > .swiper-container {
    padding-bottom: 0;
  }
}
</style>
